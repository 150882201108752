.swiper--logo {
    .swiper-wrapper {
        align-items: center;
    }

    .swiper__slide {
        .img-fluid {
            object-fit: contain;
            object-position: center;
        }
    }
}
;@import "sass-embedded-legacy-load-done:232";