.cta {
    padding: $pane-spacer;
    background-size: cover;
    background-position: center;
    position: relative;
    color: $white;
    z-index: 1;

    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($secondary, 0.25);
        z-index: -1;
        pointer-events: none;
    }

    @include media-breakpoint-up(md) {
        padding: #{$pane-spacer * 1.5}; // 60px
    }

    @include media-breakpoint-up(lg) {
        padding: #{$pane-spacer * 2}; // 80px;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        color: inherit;
    }
}
;@import "sass-embedded-legacy-load-done:253";