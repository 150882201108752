$color-block: (
    "one": (
        "color": #e94f35,
        "size": 2.8,
    ),
    "two": (
        "color": #f39200,
        "size": 2.8,
    ),
    "three": (
        "color": #4a5497,
        "size": 1.6,
    ),
    "four": (
        "color": #be0d79,
        "size": 1.6,
    ),
    "five": (
        "color": #22b097,
        "size": 1.3,
    ),
    "six": (
        "color": #2b2171,
        "size": 1.3,
    ),
    "seven": (
        "color": #be0d79,
        "size": 1,
    ),
    "eight": (
        "color": #9f1b25,
        "size": 1,
    ),
    "nine": (
        "color": #326340,
        "size": 1,
    ),
    "ten": (
        "color": #f39200,
        "size": 1,
    ),
);

$size-block: (
    "one": (
        "size": 5,
    ),
    "two": (
        "size": 1.1875,
    ),
    "three": (
        "size": 1.9375,
    ),
    "four": (
        "size": 1.34375,
    ),
);

.location {
    font-family: $headings-font-family;
    font-size: px-to-rem(32);
    padding: 0;

    @include media-breakpoint-down(md) {
        font-size: px-to-rem(18);
    }
}

.location__item {
    @each $name, $map in $size-block {
        $i: index($size-block, $name $map);
        &:nth-child(#{$i}) {
            font-size: #{map-get($map, "size")} + "em";
            line-height: 1;

            @if #{$i} == "1" {
                line-height: 0.7;
            }
        }
    }

    &:nth-child(even) {
        opacity: 0.8;
    }
}

.line-up {
    font-family: $headings-font-family;
    font-size: px-to-rem(32);
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @include media-breakpoint-down(md) {
        font-size: px-to-rem(18);
    }
}

.line-up__item {
    display: inline-block;

    @each $name, $map in $color-block {
        $i: index($color-block, $name $map);
        &:nth-child(#{$i}) {
            color: map-get($map, "color");
            font-size: #{map-get($map, "size")} + "em";
            padding: 0 0.1em;
            line-height: 1;
        }
    }

    // Loop again
    @each $name, $map in $color-block {
        $i: index($color-block, $name $map);
        &:nth-child(#{$i + length($color-block)}) {
            color: map-get($map, "color");
            font-size: #{map-get($map, "size")} + "em";
            padding: 0 0.1em;
            line-height: 1;
        }
    }
}

;@import "sass-embedded-legacy-load-done:226";