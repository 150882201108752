label {
    width: 100%;
    display: block;
    margin-bottom: $form-label-margin-bottom;
    @include font-size($form-label-font-size);
    font-style: $form-label-font-style;
    font-weight: $form-label-font-weight;
    color: $form-label-color;
}

.wpcf7-list-item {
    display: inline-flex !important;
    margin: 0 !important;
    align-items: center;
    gap: spacer(2);

    &-label {
        line-height: 1;
    }
}
;@import "sass-embedded-legacy-load-done:261";