// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//

// scss-docs-start gray-color-variables
@use "sass:math";

$white: #fff;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $black !default;
$color-contrast-light: $white !default;

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

$red: #dc3545;
$yellow: #ffc107;
$green: #28a745;
$cyan: #17a2b8;

$primary: #aac44d;
$primary-100: #bdd652;
$primary-500: $primary;
$primary-600: #9fb94c;
$primary-700: #399236;
$primary-800: #243701;
$primary-900: #326340;

$color-base: $primary;

$secondary: #f39200;
$tertiary: #2a2170;
$quaternary: #e94f35;
$quinary: #fffbd5;
$senary: #2b2171;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: rgba($black, 0.05);
$dark: rgba($black, 0.8);
$meta-theme: $primary;

$theme-colors: (
    "primary": $primary,
    "primary-600": $primary-600,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "quaternary": $quaternary,
    "quinary": $quinary,
    "senary": $senary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "base": $color-base,
    "meta-theme": $meta-theme,
);

// Prefix for :root CSS variables
$prefix: pe-;

// Color mode light/dark or for theming
// $enable-dark-mode: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacer-unit: 4;
$spacers: ();
@for $i from 0 through 50 {
    $spacers: map-merge(
        $spacers,
        (
            $i: math.div($i * $spacer, $spacer-unit),
        )
    );
}

$enable-negative-margins: true;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $color-base;
$body-color: $quinary;
$body-color-dark: $dark;

// Links
//
// Style anchor elements.

$link-color: $quaternary !default;
$link-decoration: underline !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: null !default;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: ($spacer * 0.5);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1280px,
    xxl: 1680px,
);
// scss-docs-end grid-breakpoints
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1220px,
    xxl: 1440px,
);
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: px-to-rem(40);
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

// Container padding

$container-padding-x: $grid-gutter-width !default;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px !default;
$border-widths: (
    1: 1px,
    2: 2px,
    3: 3px,
    4: 4px,
    5: 5px,
) !default;
$border-style: solid !default;
$border-color: $gray-300 !default;
$border-color-translucent: rgba($black, 0.175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0.375rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.5rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;

// scss-docs-start box-shadow-variables
$box-shadow: 0 0.5rem 1rem rgba(var(--#{$prefix}body-color-rgb), 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba(var(--#{$prefix}body-color-rgb), 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba(var(--#{$prefix}body-color-rgb), 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba(var(--#{$prefix}body-color-rgb), 0.075) !default;
$box-shadow-hover: $box-shadow-lg;

// Typography
$font-family-sans-serif: "Kievitot";
$font-family-base: $font-family-sans-serif;

// Enable responsive font-sizes
$enable-responsive-font-sizes: true;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
// scss-docs-start collapse-transition
$transition-collapse: height 0.35s ease !default;
$transition-collapse-width: width 0.35s ease !default;

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
    "1x1": 100%,
    "4x3": calc(3 / 4 * 100%),
    "16x9": calc(9 / 16 * 100%),
    "21x9": calc(9 / 21 * 100%),
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: 100%;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-base * 3.5 !default;
$h2-font-size: $font-size-base * 3.25 !default;
$h3-font-size: $font-size-base * 3 !default;
$h4-font-size: $font-size-base * 2.5 !default;
$h5-font-size: $font-size-base * 2 !default;
$h6-font-size: $font-size-base !default;

// scss-docs-start type-variables
$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 0.875em !default;

$sub-sup-font-size: 0.75em !default;

$text-muted: var(--#{$prefix}secondary-color) !default;

$initialism-font-size: $small-font-size !default;

// scss-docs-end font-variables
$headings-margin-bottom: ($spacer * 0.5);
$headings-font-family: "Troika";
$headings-font-weight: $font-weight-normal;
$headings-line-height: 1.2;
$headings-color: inherit;

// Inputs vars
$input-bg: $light;
$input-padding-y: spacer(6);
$input-padding-x: spacer(8);
$input-font-family: $font-family-sans-serif;
$input-border-radius: $border-radius;
$input-border: unset;
$input-font-size: $font-size-base;

// Animations
$global-duration: 300ms;

// Pane spacers
$pane-spacer: spacer(10); // 40px

$pane-spacers: (
    xs: #{$pane-spacer},
    md: #{$pane-spacer * 1.5},
    lg: #{$pane-spacer * 3},
);

;@import "sass-embedded-legacy-load-done:3";