.search {
    position: relative;
}

.searchbar__container {
    display: flex;
    align-items: stretch;
}

.searchbar__icon {
    @extend .btn;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: unset;
    background: $primary;
    transform: translateX(-30px);
    margin-right: -30px;
}

.searchbar__field {
    padding: spacer(2);
    padding-right: 30px !important;
    min-width: 140px;

    @include media-breakpoint-up(lg){
        outline: none;
        border: 1px solid $secondary;
        transition: all $global-duration;

        &:focus {
            border: 2px solid $secondary;
        }
    }
}
;@import "sass-embedded-legacy-load-done:224";