// Default swiper
.swiper {
    &__slide {
        @extend .swiper-slide;
    }

    &__buttons {
        position: relative;
        display: flex;
        align-items: center;
        background-image: url("../../img/backgrounds/carousel-bg.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: spacer(4);

        .swiper__button {
            display: flex;
            color: $primary-800;
            position: relative;
            transform: unset;
            left: unset;
            top: unset;
            right: unset;
            bottom: unset;
            padding: spacer(2);
        }
    }

    &__button {
        display: flex;
        color: $quinary;
        border-radius: $border-radius-pill;
        position: relative;
        transform: unset;
        left: unset;
        top: unset;
        right: unset;
        bottom: unset;
        padding: spacer(4);

        &:hover {
            color: $quaternary;
        }

        &--next {
            right: spacer(3);
            transform: translateY(-50%);
        }

        &--prev {
            left: spacer(3);
            transform: translateY(-50%);
        }

        &--disabled {
            opacity: 0.75;
            pointer-events: none;
        }
    }

    .slide__text {
        position: absolute;
        left: 5vw;
        top: 0;
        right: 5vw;
        bottom: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    @each $name, $breakpoint in $grid-breakpoints {
        &.swiper--destroy-#{$name} {
            @include media-breakpoint-up($name) {
                margin: 0 -20px;

                .swiper__slide {
                    margin: 0 !important;
                    padding: 20px;
                    width: 25% !important;

                    &.swiper-slide-duplicate {
                        display: none;
                    }
                }

                .swiper-wrapper {
                    transform: none !important;
                    flex-wrap: wrap;
                    height: unset !important;
                }
            }
        }
    }

    &--image_text {
        .slide__text {
            position: relative;
            left: 0;
            right: 0;
            padding: 5vw;

            @include media-breakpoint-up(lg) {
                position: absolute;
                left: 5vw;
                right: 5vw;
                padding: 0;
            }
        }

        .swiper__slide {
            .img-fluid {
                @include media-breakpoint-down(lg) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    object-fit: cover;
                    min-height: 70vh;
                }
            }
        }
    }
}

// Used for cards/posts carousel
.swiper-container {
    margin-left: -#{spacer(4)};
    margin-right: -#{spacer(4)};
}

.swiper--image-video {
    @include media-breakpoint-up(lg) {
        padding: 0 15% !important;
    }

    .swiper__slide {
        position: relative;
    }
}

;@import "sass-embedded-legacy-load-done:230";