.pane {
    display: block;

    @each $breakpoint, $pane-spacer in $pane-spacers {
        @include media-breakpoint-up($breakpoint) {
            padding: $pane-spacer 0;
        }
    }

    &.bg--default {
        & + .pane.bg--default {
            padding-top: 0;
        }
    }
}

@each $name, $color in $theme-colors {
    .pane.bg--#{$name} {
        + .pane.bg--#{$name} {
            padding-top: 0;
        }
    }
}

.pane--illustration {
    position: relative;
    overflow: hidden;
    background: $primary-600;
    padding-bottom: 0;
    padding-top: 0;
}

.pane--carousel {
    position: relative;
}

;@import "sass-embedded-legacy-load-done:212";