// default icon style
.icon {
    display: inline-flex;
    height: 100%;

    svg {
        height: 100%;
    }
}

.icon--return {
    transform: scaleX(-1);
}

// loop trough thema colors
@each $name, $color in $theme-colors {
    .icon--#{$name} {
        color: $color;
    }
}

svg {
    fill: currentColor;
    width: 100%;
}

// Styling for svg sprite
#svg-sprite {
    visibility: hidden;
    max-height: 0;
    z-index: -100;
    overflow: hidden;
    position: absolute;
}

.svg-small-leaves {
    position: absolute;
    max-width: 112px;
    right: 0;
    top: 0;
    transform: translateY(-130%);

    @include media-breakpoint-down(lg) {
        max-width: 69px;
        transform: translateY(-80%);
    }
}

;@import "sass-embedded-legacy-load-done:216";