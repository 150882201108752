// Breakout options on row

$icon-sizes: ();

@for $i from 1 through 50 {
    $icon-sizes: map-merge($icon-sizes, ("-" + $i * 2: px-to-rem($i * 2)));
}

// Icon class is icon--32 of icon-lg--32
$utilities: map-merge(
    $utilities,
    (
        "icon": (
            property: (
                font-size,
                height,
                width,
            ),
            class: icon,
            responsive: true,
            values: $icon-sizes,
        ),
    )
);

// Breakout options on containers
$container-fluid-sides: (left, right, center);

// Carousel sizes
$carousel-sizes: (
    "one-third": 3,
    "one-fourth": 4,
    "one-fifth": 5,
    "two-fourth": 2,
    "two-third": 1.5,
    "three-fourth": 1.3333333333334,
    "full": 1,
);

// Break-out container
@each $container-fluid-side in $container-fluid-sides {
    .container-breakout-#{$container-fluid-side} {
        @include container-fluid(#{$container-fluid-side}, none);
    }
}

@each $name, $breakpoint in $grid-breakpoints {
    @each $container-fluid-side in $container-fluid-sides {
        .container-breakout-#{$name}-#{$container-fluid-side} {
            @include container-fluid(#{$container-fluid-side}, #{$name});
        }
    }
}

@each $name, $breakpoint in $grid-breakpoints {
    .container-breakout-#{$name}-none {
        @include container-fluid("", #{$name}, none);
    }
}

// Carousel destroy
.carousel {
    @each $carousel-size, $slide-width in $carousel-sizes {
        &--#{$carousel-size} {
            &.carousel-destroy {
                @include carousel-destroy(none);

                @each $name, $breakpoint in $grid-breakpoints {
                    &.carousel-destroy-#{$name}-restore {
                        @include carousel-destroy(#{$name}, none);
                    }
                }
            }

            @each $name, $breakpoint in $grid-breakpoints {
                &.carousel-destroy-#{$name} {
                    @include carousel-destroy(#{$name});

                    @each $name, $breakpoint in $grid-breakpoints {
                        &.carousel-destroy-#{$name}-restore {
                            @include carousel-destroy(#{$name}, none);
                        }
                    }
                }
            }
        }
    }
}

// Pane spacers utilities
$utilities: map-merge(
    $utilities,
    (
        "pane--spacer": (
            property: padding,
            class: pane--spacer,
            responsive: true,
            values: (
                0: 0,
                1: spacer(5) 0,
                2: spacer(10) 0,
                3: spacer(15) 0,
                4: spacer(20) 0,
                5: spacer(25) 0,
                6: spacer(30) 0,
            ),
        ),
    )
);

// Overflow utilities
.overflow-hidden-x {
    overflow-x: hidden;
}

.overflow-hidden-y {
    overflow-y: hidden;
}

// Z-index utilities
@for $i from 1 through 20 {
    .z-#{$i} {
        z-index: #{$i};
    }
}

.absolute-center-y {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
}

.absolute-center-x {
    position: absolute;
    transform: translatex(-50%);
    left: 50%;
}

.absolute-center-auto {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Object fit utilities
.object-fit--contain {
    object-fit: contain;
    min-height: 100%;
}

.object-fit--cover {
    object-fit: cover;
    width: 100%;
    max-width: unset !important;
    min-height: 100%;
}

.no-collapse {
    display: flow-root;
}

@each $name, $color in $theme-colors {
    .bg--#{$name} {
        background-color: $color;
    }
}

.title {
    @extend %title;

    @include media-breakpoint-down(lg) {
        font-size: min(max(40px, 8vw), 10vw);
    }
}

.title--label {
    @extend %title-label;
}

.clipPath {
    width: 0 !important;
    height: 0 !important;
}

.tcmp-image__buttons {
    margin-top: spacer(6);

    @include media-breakpoint-down(lg) {
        .btn {
            background-color: darken($primary, 20%);
        }
    }

    @include media-breakpoint-up(lg) {
        margin-top: unset;
    }
}

.cars {
    position: relative;
    z-index: 1;
}

;@import "sass-embedded-legacy-load-done:179";