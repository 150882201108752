.menu--primary {
    font-family: $headings-font-family;
    justify-content: center;
    padding: spacer(8);
    color: $primary-900;

    @include media-breakpoint-up(lg) {
        padding: unset;
    }

    .menu__item {
        color: inherit;
        font-size: px-to-rem(20);
    }

    // Menu item level 1
    & > .menu__item {
        display: flex;
        align-items: stretch;
        transition: background-color $global-duration ease-in;
        padding: unset;

        &:hover {
            @include media-breakpoint-up(lg) {
                .menu__link {
                    background-color: $primary;
                }
            }
        }
    }

    .menu__link {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 1rem;

        &.active {
            background-color: $primary;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:238";