.pagination-block {
    .pagination {
        display: flex;
        align-items: center;
        padding-left: 0;
        text-decoration: none;
        list-style-type: none;
        color: $color-base;
        font-weight: $font-weight-bold;
        .prev, .next {
            align-items: center;
            display: flex;
        }
        a {
            color: $color-base;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
        }
        li {
            &:before {
                display: none;
            }
            margin: 0 spacer(2);

            &:first-of-type {
                margin-left: auto !important;
            }

            &:last-of-type {
                margin-right: auto !important;
            }

            &.prev {
                margin-right: auto
            }
            &.next {
                margin-left: auto;
            }

            &.current {
                color: $primary;
            }
            

            &.btn {
                color: $secondary;
                a {
                    color: $secondary;
                    text-decoration: none;
                }
                &.disabled {
                    opacity: .6;
                    &:before {
                        display: none;
                    }
                    &:hover {
                        transition: none; 
                    }
                }
            }
        }

        .first {
            display: flex;
            flex-direction: row-reverse;

            .material-icons {
                transform: scaleX(-1);
            }
        }
    }   
} 
;@import "sass-embedded-legacy-load-done:252";