$darken-amount: 10%;
$button-transition-duration: $global-duration * 1.2;

.btn {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    border-radius: $border-radius;
    background: initial;
    line-height: 1.3;
    padding: spacer(5) spacer(8);
    color: initial;
    text-decoration: none;
    color: $white;
    width: auto;
    gap: spacer(4); // min amount of space between text and icon
    z-index: 1;
    font-size: px-to-rem(20);
    border: none;
    font-family: $headings-font-family;

    &:hover {
        text-decoration: none;
        color: $white;
    }
}

// Button colors
@each $name, $color in $theme-colors {
    .btn--#{$name} {
        background: $color;
        transition: background-color $button-transition-duration,
            color $button-transition-duration;

        clip-path: url(#button-clip-path);

        &:hover {
            background-color: darken($color, 20%);
        }

        // Button colors inverted
        &-inverted {
            color: $color;
            background: transparent;
            box-shadow: inset 0 0 0 2px $color;
            transition: background-color $button-transition-duration,
                color $button-transition-duration;

            &:after {
                width: 0;
            }

            &:hover {
                background-color: $color;
            }
        }
    }
}

.btn-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: spacer(4);
    justify-content: flex-start;
}

// Used to show icon first, button text second
.btn--reversed {
    flex-direction: row-reverse;
}

.btn--rounded {
    border-radius: 50rem;
    font-weight: bold;
    padding: spacer(3) spacer(6);
    text-align: center;

    &:after {
        width: 0;
    }
}

/* Add this class to any button with an icon inside of it
this will take care of the icon padding when used before or after text
*/
.btn__icon {
    display: flex;
    align-items: center;
    position: relative;

    .icon:not(:first-child) {
        padding-right: 0;
    }

    .icon:not(:last-child) {
        padding-left: 0;
    }
}

.btn--center {
    width: fit-content;
    margin: 0 auto;
}

.btn--video {
    &:after {
        content: "play_arrow";
        position: absolute;
        color: #233601;
        background-image: url("../../img/backgrounds/play.svg");
        background-repeat: no-repeat;
        background-position: center;
        top: 0;
        left: 0;
        right: 0;
        text-align: center;
        font-family: "Material Icons";
        background-size: contain;
        padding: spacer(4);
        top: 50%;
        transform: translateY(-50%);
        transition: color $global-duration;
        font-size: px-to-rem(20);

        @include media-breakpoint-up(lg) {
            font-size: px-to-rem(32);
        }
    }

    &:hover {
        &:after {
            color: $primary;
        }
    }
}

.btn--disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: default;
}

.btn-return {
    padding: spacer(2) spacer(4);

    & > .icon {
        pointer-events: none;
    }
}

.button-ticket__container {
    position: relative;
    z-index: 100;

    &:hover {
        .btn--ticket {
            transform: translateY(0) rotate(6deg);
        }

        .button--ticket--shadow {
            transform: translateY(-5px) rotate(6deg);
        }
    }
}

.btn--ticket {
    display: flex;
    position: relative;
    color: $white;
    background-color: $secondary;
    font-family: $headings-font-family;
    padding: spacer(16) spacer(4) spacer(2) spacer(4);
    border-radius: unset;
    mask-image: url("../../img/ticket-button.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    height: calc(136px + 20px);
    transform: translateY(-20px) rotate(8deg);
    transition: transform 400ms;
    line-height: 1;
    max-width: 109px;
    text-align: center;

    @include media-breakpoint-up(lg) {
        font-size: px-to-rem(18);
        max-width: 129px;
        padding: spacer(16) spacer(4) spacer(6) spacer(4);
    }
}

.button--ticket--shadow {
    @extend .btn--ticket;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 5px;
    background-color: #badaed;
    opacity: 0.88;
    width: 100%;
    height: calc(100% + 5px);
    mask-size: cover;
    mix-blend-mode: multiply;
}

;@import "sass-embedded-legacy-load-done:214";