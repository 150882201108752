.breadcrumbs {
    padding: spacer(4) 0;
    background: rgba($black, 0.11);
}

.breadcrumbs__nav {
    display: flex;
    align-items: center;
    @extend .list;
    margin: 0;
}

.breadcrumbs__seperator {
    margin: 0 spacer(2);
}

.breadcrumb_last {
    opacity: 0.75;
}
;@import "sass-embedded-legacy-load-done:234";