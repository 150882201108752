%tab-item-border {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    background-color: #d8d8d8;
}

%title {
    font-size: $h2-font-size;
    line-height: 1.4;
    font-family: $headings-font-family;
}

%title-label {
    @extend %title;
    background-color: $quaternary;
    color: $white;
    padding: 0.1em 0.2em;
    transform: rotate(-2.038deg);
    transform-origin: center;
    width: max-content;
    line-height: 1;
    z-index: 20;
}

;@import "sass-embedded-legacy-load-done:4";